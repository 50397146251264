









































































































import { Component, Prop } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import { UserManagementUser } from "@/models/user";
import accessControl from "@/api/access_control";
import { Account } from "@/interfaces/Account";
import UserOverviewEmployee from "@/components/usermanagement/employees/UserOverviewEmployee.vue";
import AccountFilter from "@/components/form-components/search/AccountFilter.vue";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import TheHeader from "@/components/TheHeader.vue";
import Page from "@/components/layouts/Page.vue";
import { accountLabel } from "@/utils/accountDisplayFormatter";

@Component({
  props: {
    selectedAccountId: String,
  },
  methods: { accountLabel },
  components: {
    UserOverviewEmployee,
    Loader,
    AccountFilter,
    Page,
    TheHeader,
  },
  name: "EmployeeUserManagement",
})
@Translatable("usermanagement.employees")
export default class UserManagement extends mixins(TranslatableComponent) {
  private isLoadingUsers: boolean = false;
  private isLoadingAccounts: boolean = false;

  private users: UserManagementUser[] = [];

  private accounts: Account[] = [];
  private account: Account | null = null;

  get hasAccounts(): boolean {
    return this.accounts.length > 0;
  }

  get hasUsers(): boolean {
    return this.users.length > 0;
  }

  fetchMyAccountUsers(accountId: string) {
    this.isLoadingUsers = true;
    accessControl.account_users
      .index(accountId)
      .then((data) => {
        this.users = data.data;
      })
      .catch((error) => {
        this.$snotify.error(
          this.$i18n.t("api.loading.error", {
            model: this.$i18n.tc(UserManagementUser.modelTranslationField, 2),
          })
        );
        this.users = [];
      })
      .finally(() => {
        this.isLoadingUsers = false;
      });
  }

  mounted() {
    this.isLoadingAccounts = true;
    this.users = [];
    this.accounts = [];
    accessControl.accounts
      .accounts()
      .then((response) => {
        this.accounts = response.data;
      })
      .catch(() => {
        this.$snotify.error(
          this.$i18n.t("api.loading.error", {
            model: this.$i18n.tc(`models.accounts.model_name`, 2),
          })
        );
        this.accounts = [];
        this.users = [];
      })
      .finally(() => {
        this.isLoadingAccounts = false;
      });
  }

  private onAccountChanged(account: Account | null) {
    this.account = account;
    this.users = [];
    if (account) {
      this.fetchMyAccountUsers(account.id);
    }
  }
}
