
































import Loader from "@/components/Loader.vue";
import ScrollIndicator from "@/components/tables/ScrollIndicator.vue";
import { Component, Prop } from "vue-property-decorator";
import { UserManagementUser } from "@/models/user";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { chain } from "lodash";

@Component({
  components: {
    Loader,
    ScrollIndicator,
  },
})
@Translatable("usermanagement")
export default class UserOverviewEmployee extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  @Prop()
  private users!: UserManagementUser[];

  UserManagementUser = UserManagementUser;

  tableUserKey(user: UserManagementUser): string {
    return `row-${user.id}${user.account.id}`;
  }

  get sortedUsers(): UserManagementUser[] {
    return chain(this.users).sortBy("fullName").sortedUniqBy("id").value();
  }

  private goToUserDetail(user: UserManagementUser) {
    this.$router.push({
      name: "usermanagement-detail-employees",
      params: { profileId: user.id, accountId: user.account.id },
    });
  }
}
